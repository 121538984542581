<template>
  <ModalBase :is-show="isOpen" @close="closeModal()">
    <template #content>
      <div class="bg modalslide" @touchstart="e = $event" @touchend="swipe">
        <div class="pcslide">
          <template v-for="(src, i) in pc_images"  :key="'pc.' + i">
            <a v-if="typeof(src) == 'object' && src.url" v-bind:href="src.url" target="_blank">
              <img
                :src="src.image"
                :class="{ active: pc_active == i }"
                :style="{ 'z-index': [pc_active == i ? 3 : 1] }"
                alt=""
              />
            </a>
            <img v-else
              :src="typeof(src) == 'object' ? src.image : src"
              :class="{ active: pc_active == i }"
              :style="{ 'z-index': [pc_active == i ? 3 : 1] }"
              alt=""
            />
          </template>
        </div>
        <div class="spslide">
          <template v-for="(src, i) in sp_images"  :key="'sp.' + i">
            <a v-if="transision_mode && src.url" v-bind:href="src.url" target="_blank">
              <img
                :src="src.image"
                :class="{ active: sp_active == i }"
                :style="{ 'z-index': [sp_active == i ? 3 : 1] }"
                alt=""
              />
            </a>
            <img v-else
              :src="typeof(src) == 'object' ? src.image : src"
              :class="{ active: sp_active == i }"
              :style="{ 'z-index': [pc_active == i ? 3 : 1] }"
              alt=""
            />
          </template>
        </div>
        <div v-if="pc_length > 1" class="controll pccontroll">
          <button type="button" class="prev" @click="prev">
            <img src="/common/img/sp_scroll_prev.png" alt="" />
          </button>
          <button type="button" class="next" @click="next">
            <img src="/common/img/sp_scroll.png" alt="" />
          </button>
        </div>
        <div v-if="sp_length > 1" class="controll spcontroll">
          <button type="button" class="prev" @click="prev">
            <img src="/common/img/sp_scroll_prev.png" alt="" />
          </button>
          <button type="button" class="next" @click="next">
            <img src="/common/img/sp_scroll.png" alt="" />
          </button>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modal-base.vue";
import { useModal } from "@/composables/modal";

export default {
  components: { ModalBase },
  props: {
    pc_images: { type: Array, required: true },
    sp_images: { type: Array, required: true },
    transision_mode: { type: Boolean, required: true },
  },
  setup(props, { expose }) {
    // モーダル制御
    const { openModal, closeModal, isOpen, onOpen } = useModal();

    // モーダル利用画面からの表示用
    expose({ openModal });

    // this.アクセス用 template, optionsへの公開
    return { isOpen, closeModal, onOpen };
  },
  data: function () {
    return {
      notices: null,
      pc_active: 0,
      sp_active: 0,
      pc_length: 0,
      sp_length: 0,
      timeout_id: null,
    };
  },
  mounted: function () {
    this.onOpen(() => {
      this.pc_length = this.pc_images.length;
      this.sp_length = this.sp_images.length;
      if (this.transision_mode) this.imageTransition();
    });
  },
  methods: {
    prev: function () {
      if (--this.pc_active < 0) {
        this.pc_active = this.pc_length - 1;
      }
      if (--this.sp_active < 0) {
        this.sp_active = this.sp_length - 1;
      }
      if (this.transision_mode) this.imageTransition();
    },
    next: function () {
      if (++this.pc_active >= this.pc_length) {
        this.pc_active = 0;
      }
      if (++this.sp_active >= this.sp_length) {
        this.sp_active = 0;
      }
      if (this.transision_mode) this.imageTransition();
    },
    imageTransition: function() {
      let pc_elm = document.getElementsByClassName('pcslide')[0]
      let sp_elm = document.getElementsByClassName('spslide')[0]
      // 画像が2枚以上あるときのみ遷移させる
      if (pc_elm && window.getComputedStyle(pc_elm).display != 'none' && this.pc_length > 1 ||
          sp_elm && window.getComputedStyle(sp_elm).display != 'none' && this.sp_length > 1) {
        clearTimeout(this.timeout_id)
        this.timeout_id = setTimeout(this.next, 2000);
      } else if (this.isOpen == true) {
        this.timeout_id = setTimeout(this.imageTransition, 2000);
      } else {
        clearTimeout(this.timeout_id);
      }
    },
    swipe: function (e) {
      var move = this.e.changedTouches[0].pageX - e.changedTouches[0].pageX;
      if (move > 100) {
        this.next();
      } else if (move < -100) {
        this.prev();
      }
    },
  },
};
</script>

<style scoped>
.modal {
  width:100%;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
  z-index:99;
  background:rgba(0,0,0,0.7);
  overflow:auto;
}
.modal.pc_hide {
  display:none;
}
.modal_section {
  width:max-content;
  max-width:1200px;
  min-width:600px;
  max-height:680px;
  margin:0 auto 15px;
  padding-top:60px;
  position:relative;
  top:40%;
  transform:translateY(-50%);
}
.modal_section .bg {
  padding:0 0 20px;
  background:#fff;
}
.informationpage .modal_section .bg {
  padding:0;
}
.informationpage .modal_info_bg .modal_section .bg {
  padding:30px;
}
.informationpage .modal_section .modalslide.bg {
  background:transparent;
}
.conferencepage .modal_section .bg .comment {
  padding:30px 30px 20px;
}
.modal_section .modalslide {
  padding:0;
  background:transparent;
}
.modal_section .modalslide img {
  margin:0;
  display:block;
  opacity:0;
  transition:all ease .5s;
  position:absolute;
  bottom:0;
  left:50%;
  transform:translateX(-50%);
}
.modal_section .modalslide .controll img {
  opacity:1;
  position:relative;
}
.modal_section .modalslide img.active {
  opacity:1;
  position:relative;
}
.modal_section .modalslide .pcslide, .modal_section .modalslide .spslide {
  position:relative;
}
.modal_section .modalslide .pcslide, .modal_section .modalslide .controll.pccontroll {display:block;}
.modal_section .modalslide .spslide, .modal_section .modalslide .controll.spcontroll {display:none;}
.modal_section .controll {
  width:100%;
  height:0;
  overflow:visible;
}
.modal_section .controll .prev, .modal_section .controll .next {
  width:30px;
  position:absolute;
  top:50%;
  cursor:pointer;
  z-index:5;
}
.modal_section .controll .prev {
  left:-45px;
}
.modal_section .controll .next {
  right:-45px;
}
.modal_info_bg .bg {
  padding:30px;
}
.modal .top_info a.info_close, a.modal_close {
  width:18px;
  height:18px;
  display:block;
  position:absolute;
  top:20px;
  right:20px;
  z-index:9;
}
.modal .top_info a.info_close::after, a.modal_close::after {
  content:"";
  width:40px;
  height:40px;
  background:transparent;
  border:solid 1px #fff;
  display:block;
  border-radius:25px;
  position:absolute;
  top:-12px;
  left:-12px;
}
.modal .top_info a.info_close rect, a.modal_close rect {
  fill:#fff;
}
a.modal_close svg {
  width:18px;
  height:18px;
}
.modal_section h1 {
  font-size:22px;
  font-weight:bold;
  line-height:1.3;
  text-align:center;
  padding:40px 10px;
}
.modal_section h1.simple {
  padding:60px 0 0;
  background:none;
}
.modal .button a, .modal .button button {
  font-size:18px;
  padding:15px 10px;
}
.informationpage .modal_section a.info_close {
  width:18px;
  height:18px;
  display:block;
  position:absolute;
  top:20px;
  right:20px;
  z-index:1;
}
.informationpage .modal_section h1.help_modal_title {
  font-size:18px;
  line-height:1.4;
  text-align:left;
  padding:0 0 0 32px;
  margin:0 0 15px;
  position:relative;
  background:transparent;
}
.informationpage .modal_section h1.help_modal_title svg {
  width:24px;
  display:inline-block;
  margin:0 4px 0 0;
  position:absolute;
  left:0;
}
</style>
