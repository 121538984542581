<template>
  <template v-if="isMounted && isShow">
    <Teleport to="#main">
      <div :class="modalClass">
        <section class="modal_section">
          <a class="modal_close" @click="emit('close')">
            <icon name="ico-close" viewBox="0 0 18 18"></icon>
          </a>
          <slot name="content" />
        </section>
      </div>
    </Teleport>
  </template>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'

const props = defineProps({
  isShow: { type: Boolean, required: true },
  styleClass: { type: String, default: '' }
})
const emit = defineEmits(['close'])

const modalClass = computed(() => {
  return `modal ${props.styleClass}`.trim()
})

watch(props, () => {
  if (modalClass.value.split(' ').includes('fix_modal')) return

  if (props.isShow) {
    document.body.classList.add('modalopen')
  } else {
    document.body.classList.remove('modalopen')
  }
})

const isMounted = ref(false)
onMounted(() => {
  isMounted.value = true
})

onUnmounted(() => {
  document.body.classList.remove('modalopen')
})
</script>
