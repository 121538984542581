<template>
  <div class="pageTop">
    <a>
      <svg id="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 41">
        <path class="cls-1" d="M90.71,280.29a1,1,0,0,0-1.42,0l-16,16a1,1,0,0,0,1.42,1.42L89,283.41V320a1,1,0,0,0,2,0V283.41l14.29,14.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" transform="translate(-73 -280)"/>
      </svg>
    </a>
  </div>
</template>

<style scoped>
.pageTop {
	width:76px;
  height:76px;
  margin:0 auto;
  z-index:11;
  clear:both;
  position:fixed;
	visibility:visible;
	bottom:20px;
	right:0;
	opacity:0;
	transition:opacity ease .5s;
}
.pageTop.visible {
	opacity:1;
}
.pageTop a {
  width:76px;
  height:76px;
	display:block;
	position: relative;
	background:#000;
}
.pageTop svg {
	width:38%;
	position:absolute;
	top:50%;
	left:50%;
	transform:translateY(-50%) translateX(-50%);
}
.pageTop svg path {
	fill:#fff;
}

@media screen and (max-width:767px) {
	.pageTop, .pageTop a {
		width:50px;
		height:50px;
	}
}
</style>

<script setup>
import { onMounted } from 'vue';

const observer = new IntersectionObserver((entries) => {
  document.querySelector('.pageTop').classList.toggle('visible', !entries[0].isIntersecting)
}, {})

onMounted(() => {
  observer.observe(document.querySelector('header'))
  document.querySelector('.pageTop').addEventListener('click', () => window.scroll({ top: 0, behavior: 'smooth' }))
})
</script>
