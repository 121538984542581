<template>
  <header>
    <ScrollTop />
    <div class="headertop">
      <div class="logo" v-if="apartments">
        <router-link to="/" v-if="user_session">
          <img v-if="apartments.attributes.maintainer_logo_image_url" v-bind:src="apartments.attributes.maintainer_logo_image_url">
          <img v-else-if="apartments.attributes.image_url" v-bind:src="apartments.attributes.image_url" v-bind:alt="apartments.attributes.name">
          <p>{{apartments.attributes.name}}</p>
        </router-link>
      </div>
      <a class="faqmenu" href="https://help.m-cloud.jp/" target="_blank">
        <svg class="svg_faq" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.79 25.85">
          <path d="M261.57,353.3c-6.29,0-11.4,4.79-11.4,10.71s5.11,10.72,11.4,10.72a12.09,12.09,0,0,0,3.59-.55,15,15,0,0,0,4.3,3l0-5.43A10.4,10.4,0,0,0,273,364c0-5.92-5.1-10.71-11.39-10.71Z" transform="translate(-249.17 -352.3)"/>
          <path d="M259.59,360.49c1.22-2.23,4.66-2.37,4.66.7,0,2.09-2.64,2-2.64,4.5" transform="translate(-249.17 -352.3)"/>
          <path d="M261.61,368.71a2.06,2.06,0,0,0,0,.36" transform="translate(-249.17 -352.3)"/>
        </svg>
      </a>
    </div>
    <div class="usermenuarea">
      <ModalNotices ref="modalNoticesRef" />
      <a class="infomenu" @click="openNotices">
        <icon name="ico-info" class="svg_info" viewBox="0 0 24.08 24.36"></icon>
      </a>
      <a class="usermenu" v-on:click="toggleUserMenu">
        <svg class="svg_user" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.39 24.51">
          <path d="M324.89,237.76a1,1,0,0,1-.34-.06c-3.68-1.34-5.4-3.84-5.4-7.87,0-3.54,2.47-6.21,5.74-6.21s5.75,2.67,5.75,6.21c0,2.61-.57,6.26-5.43,7.88A1.13,1.13,0,0,1,324.89,237.76Zm0-12.14c-2.16,0-3.74,1.77-3.74,4.21,0,3,1.13,4.81,3.76,5.86,2.61-1,3.73-2.74,3.73-5.86C328.64,227.39,327.06,225.62,324.89,225.62Z" transform="translate(-314.2 -223.62)"/>
          <path d="M334.59,248.14H315.2a1,1,0,0,1-1-1v-4.25a6.34,6.34,0,0,1,6.33-6.33h8.73a6.34,6.34,0,0,1,6.33,6.33v4.25A1,1,0,0,1,334.59,248.14Zm-18.39-2h17.39v-3.25a4.34,4.34,0,0,0-4.33-4.33h-8.73a4.34,4.34,0,0,0-4.33,4.33Z" transform="translate(-314.2 -223.62)"/>
        </svg>
      </a>

      <div class="userconfig" v-bind:class="{open: userMenu}">
        <a class="lnavi_close" v-on:click="closeUserMenu">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <rect x="3.07" y="16.2" width="23.86" height="1.59" transform="translate(10.41 -13.63) rotate(45)"/>
            <rect x="3.07" y="16.2" width="23.86" height="1.59" transform="translate(-13.63 7.59) rotate(-45)"/>
          </svg>
        </a>
        <ul>
          <li v-if="user_session"><router-link to="/account/">個人の設定</router-link></li>
          <li><a v-on:click="$emit('logout')">ログアウト</a></li>
        </ul>
        <p class="lastlogin" v-if="user_session">
          前回ログイン<br>
          <span v-if="user_session.attributes.last_login_at">
            {{formatJSTDateTime(user_session.attributes.last_login_at)}}
          </span>
          <span v-else>
            なし
          </span>
        </p>
      </div>

      <p v-if="user_session">{{user_session.attributes.name}} [{{user_session.attributes.role_name}}]</p>

    </div>
  </header>
</template>

<script>
import ModalNotices from '@/components/modal/notices.vue';
import ScrollTop from '@/components/scroll-top.vue';
export default {
  props: ['user_session', 'apartments'],
  components: {
    ModalNotices,
    ScrollTop,
  },
  data: function () {
    return {
      userMenu: false,
    }
  },
  methods: {
    init: function () {
    },
    closeUserMenu: function () {
      this.userMenu = false;
    },
    toggleUserMenu: function () {
      this.userMenu = !this.userMenu;
    },
    openNotices: function () {
      this.$refs.modalNoticesRef.openModal()
    },
  },
  mounted: function () {
    this.init();
    document.body.addEventListener('click', function (e) {
      if (e.target.closest('.userconfig') == null && e.target.closest('.usermenuarea a') == null) {
        this.closeUserMenu();
      }
    }.bind(this));
  },
  watch: {
    '$route': function () {
      this.init();
      this.closeUserMenu();
    }
  },
}
</script>
