<template>
  <div id="main" class="green" v-bind:class="this.$route.meta.group" v-if="apartments">
    <div class="loader">Loading...</div>
    <PageHeader v-bind:user_session="user_session" v-bind:apartments="apartments" v-on:logout="$emit('logout')" />

    <main class="contentsarea">
      <a class="lnavi_open" v-on:click="openGlobalMenu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 25.99">
          <rect width="30" height="2"/>
          <rect y="10" width="30" height="2"/>
          <path d="M12.85,35.88l.41-6.4h1l2.06,5.22,2.13-5.22h.88l.42,6.4H19l-.34-5.64-2.18,5.37H16l-2.14-5.37-.35,5.64Z" transform="translate(-11 -10)"/>
          <path d="M21.52,35.88v-6.4h3.67v.63h-3v2.12h2.61v.6H22.2v2.42h3.06v.63Z" transform="translate(-11 -10)"/>
          <path d="M26.84,35.88v-6.4h1L31.15,35V29.48h.66v6.4h-.92l-3.41-5.71v5.71Z" transform="translate(-11 -10)"/>
          <path d="M34.49,33.61a1.74,1.74,0,1,0,3.48,0V29.48h.67v4.07A2.22,2.22,0,0,1,36.21,36a2.16,2.16,0,0,1-2.41-2.44V29.48h.69Z" transform="translate(-11 -10)"/>
        </svg>
      </a>

      <LeftNavi v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:globalMenu="pcMenu" v-bind:view="'pc'"/>
      <LeftNavi v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:globalMenu="spMenu" v-bind:view="'sp'"/>

      <Guide v-bind:category_id="22" v-if="$route.name == 'Home'"></Guide>

      <router-view v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:buildings="buildings" v-bind:holiday="holiday" ref="body"/>
    </main>

    <PageFooter/>
  </div>

</template>

<script>
import PageHeader from '@/components/header.vue';
import PageFooter from '@/components/footer.vue';
import LeftNavi from '@/components/leftNavi.vue';
import Guide from '@/components/guide.vue';

export default {
  components: {
    PageHeader,
    PageFooter,
    LeftNavi,
    Guide
  },
  props: ['user_session', 'apartments', 'buildings', 'holiday'],
  data: function () {
    return {
      pcMenu: true,
      spMenu: false,
      images: [],
    }
  },
  methods: {
    // グロナビを開く
    openGlobalMenu: function () {
      this.pcMenu = true;
      this.spMenu = true;
    },
    // グロナビを閉じる
    closeGlobalMenu: function () {
      this.pcMenu = false;
      this.spMenu = false;
    },
  },
  created: function () {
    document.body.addEventListener('click', function (e) {
      // スマフォ時グロナビを閉じる
      if (e.target.closest('.lnavi_open') == null) {
        this.spMenu = false;
      }
      // サブメニューを閉じる
      if (e.target.closest('.submenubtn') == null) {
        document.querySelectorAll('.submenubtn').forEach(function (elem) {
          elem.classList.remove('open');
        });
      }
      // ツールチップを閉じる
      if (e.target.closest('.position') == null) {
        document.querySelectorAll('.position').forEach(function (elem) {
          elem.querySelector('.help_link').classList.remove('open');
        });
      }
    }.bind(this));
  },
}
</script>

<style>
.ertxt {
  display: none;
}
.erbox [data-invalid]:invalid ~ .ertxt {
  display: block;
}
.upload label[data-dragover='true'] {
  border-style: solid;
  border-color: #000;
}
</style>
