<template>
  <div class="loginpage">
    <div class="loader">Loading...</div>
    <main class="contentsarea">
      <router-view/>
    </main>

    <footer class="login_footer">
      <div class="inner">
        <ul class="ftnavi">
          <li><a href="/inquiries/new" target="_blank">お問い合わせ</a></li>
          <li><a href="https://www.tsunagunet.com/profile/outline.html" target="_blank">会社概要</a></li>
          <li><a href="https://www.tsunagunet.com/privacy/" target="_blank">個人情報保護の取り組み</a></li>
          <li><a href="/terms" target="_blank">利用規約</a></li>
          <li><a href="https://help.m-cloud.jp/" target="_blank">ユーザーサポート</a></li>
        </ul>
        <p class="copyright">Copyright(c)Tsunagu Network Communications, Inc. All Rights Reserved.</p>
      </div>
    </footer>
  </div>
</template>
