import { createStore } from 'vuex'
import axios from 'axios'
import { jsonapiModule } from 'jsonapi-vuex'

const api = axios.create({
  baseURL: '/api/v2/',
  headers: {
    'Content-Type': 'application/vnd.api+json',
    // 'Content-Type': 'application/json',
  },
  // responseType: 'json',
  withCredentials: true,
})

export const store = createStore({
  state: {
    data: 'not yet.',
    guide: 'not yet.'
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setGuide(state, data) {
      state.guide = data
    }
  },
  actions: {
  },
  modules: {
    jv: jsonapiModule(api, {
      // followRelationshipsData: false,
      preserveJson: true,
      // recurseRelationships: false
    }),
  }
})
