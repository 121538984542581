// iOS 12.2未満の端末で Object.fromEntries() を使用するため polyfill導入
// see https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
import 'js-polyfill-object.fromentries';

// iOS 12.2未満の端末で IntersectionObserver を使用するため polyfill導入
// see https://developer.mozilla.org/ja/docs/Web/API/Intersection_Observer_API
import 'intersection-observer';

import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';
import { store } from './store'
import Mixin from '@/mixin'

const app = createApp(App)
app.mixin(Mixin)
app.use(router)
app.use(store)
app.use(CKEditor)
app.mount('#app')
