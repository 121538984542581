import { ref, readonly, watch } from 'vue'

export function useModal() {
  let _onOpenCallBack = null
  const _isOpen = ref(false)
  const isOpen = readonly(_isOpen)
  const resolver = ref(() => { })

  const openModal = async (options = null) => {
    return new Promise((resolve) => {
      _isOpen.value = true
      resolver.value = resolve
    })
  }

  const closeModal = (res = null) => {
    resolver.value(res)
    _isOpen.value = false
    resolver.value = () => { }
  }

  const onOpen = (callback) => {
    if (typeof callback != 'function') return

    _onOpenCallBack = callback
  }

  watch(isOpen, () => {
    if (isOpen.value && _onOpenCallBack) {
      _onOpenCallBack.call()
    }
  })

  return {
    openModal,
    closeModal,
    isOpen,
    onOpen
  }
}
