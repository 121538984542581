<template>
  <footer>
    <div class="inner">
      <div class="ftlogo">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.4 167.34">
            <title>Mcloud</title>
            <polygon points="73.66 46.9 73.66 110.36 73.66 121.24 80.82 125.62 80.82 112.56 80.82 43.54 73.66 46.9"/>
            <path d="M300,163.27l-57,25.29,2.59,45.34a48,48,0,0,0,7.29,22.85c4.47,7.13,11.69,11.72,18.57,15.53l5.83,3.57,7.16,4.38V213.45l-7.16,3.36V255.7q-.56,0-1.11,0c-11.54,0-20.89-9.95-20.89-22.24,0-10.13,6.37-18.67,15.07-21.35.31-8.14,6.66-14.65,14.48-14.65a13.82,13.82,0,0,1,5.7,1.23,24,24,0,1,1,45.53,15.17,25.32,25.32,0,0,1,8.63,17.59,26.39,26.39,0,0,1-.28,6.07l-.09.51c0,.25-.16.77-.16.76a22,22,0,0,1-15.48,16.82v-37.2l-23.09-14.91V286.3l23.09-14.13.19-.12c6.88-3.82,13.72-8.17,18.2-15.3a48.09,48.09,0,0,0,7.28-22.84L357,188.56Z" transform="translate(-217.79 -163.27)"/>
            <polygon points="109.03 56.32 87.82 42.67 87.82 114.89 109.03 103 109.03 56.32"/>
            <path d="M225.78,298.62a7.59,7.59,0,0,1,1.53.13,2.75,2.75,0,0,1,1.13.53,3.87,3.87,0,0,1,.87,1.05,12.33,12.33,0,0,1,.78,1.76L238.48,324a.57.57,0,0,0,.57.41h.48a.55.55,0,0,0,.56-.41l8.39-21.93a11.21,11.21,0,0,1,.79-1.76,3.7,3.7,0,0,1,.87-1.05,2.72,2.72,0,0,1,1.11-.53,7.25,7.25,0,0,1,1.51-.13h4.32a3.18,3.18,0,0,1,2.82,1.07,6.93,6.93,0,0,1,.72,3.68V329.6c0,.67-.31,1-.92,1h-4.12c-.59,0-.88-.33-.88-1V305.74q0-.45-.33-.45H254a.49.49,0,0,0-.52.36l-8.15,20.47a13.14,13.14,0,0,1-1,2.08,6.48,6.48,0,0,1-1.09,1.4,3.37,3.37,0,0,1-1.33.77,5.76,5.76,0,0,1-1.75.23h-1.94a5.71,5.71,0,0,1-1.75-.23,3.41,3.41,0,0,1-1.34-.77,6.48,6.48,0,0,1-1.09-1.4,14.13,14.13,0,0,1-1-2.08l-8.15-20.47a.5.5,0,0,0-.52-.36H224c-.21,0-.32.15-.32.45V329.6c0,.67-.3,1-.89,1h-4.11c-.62,0-.93-.33-.93-1V303.37a6.82,6.82,0,0,1,.73-3.68c.48-.72,1.44-1.07,2.86-1.07Z" transform="translate(-217.79 -163.27)"/>
            <path d="M267.25,319.25a11.19,11.19,0,0,0,11.18,11.18h8.85a1,1,0,0,0,1-1v-3a1,1,0,0,0-1-1h-8.85a6.18,6.18,0,0,1,0-12.36h8.85a1,1,0,0,0,1-1v-3a1,1,0,0,0-1-1h-8.85A11.2,11.2,0,0,0,267.25,319.25Z" transform="translate(-217.79 -163.27)"/>
            <path d="M318.25,308.07h-1.19a11.18,11.18,0,0,0,0,22.36h1.19a11.18,11.18,0,0,0,0-22.36Zm0,17.36h-1.19a6.18,6.18,0,0,1,0-12.36h1.19a6.18,6.18,0,0,1,0,12.36Z" transform="translate(-217.79 -163.27)"/>
            <path d="M351.79,308.48a1,1,0,0,0-1,1v9.77a6.18,6.18,0,0,1-6.18,6.18h-1.18a6.18,6.18,0,0,1-6.18-6.18v-9.77a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1v9.77a11.19,11.19,0,0,0,11.18,11.18h1.18a11.19,11.19,0,0,0,11.18-11.18v-9.77a1,1,0,0,0-1-1Z" transform="translate(-217.79 -163.27)"/>
            <path d="M297.24,319.25V300.56a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1v18.69a11.2,11.2,0,0,0,10.18,11.14.94.94,0,0,0,1-1v-3a1.1,1.1,0,0,0-.94-1.07A6.19,6.19,0,0,1,297.24,319.25Z" transform="translate(-217.79 -163.27)"/>
            <path d="M378.19,299.56a1,1,0,0,0-1,1v9.38a11.09,11.09,0,0,0-6.18-1.87h-1.18a11.18,11.18,0,0,0,0,22.36H371a11.19,11.19,0,0,0,11.18-11.18V300.56a1,1,0,0,0-1-1ZM371,325.43h-1.18a6.18,6.18,0,0,1,0-12.36H371a6.18,6.18,0,0,1,0,12.36Z" transform="translate(-217.79 -163.27)"/>
          </svg>
        </router-link>
        <p><img src="/common/img/gooddesign.png" alt="GOOD DDESIGN"></p>
      </div>
      <ul class="ftnavi">
        <li><a href="/inquiries/new" target="_blank">お問い合わせ</a></li>
        <li><a href="https://www.tsunagunet.com/profile/outline.html" target="_blank">会社概要</a></li>
        <li><a href="https://www.tsunagunet.com/privacy/" target="_blank">個人情報保護の取り組み</a></li>
        <li><a href="/terms" target="_blank">利用規約</a></li>
        <li><a href="https://help.m-cloud.jp/" target="_blank">ユーザーサポート</a></li>
      </ul>
      <p class="copyright">Copyright(c)Tsunagu Network Communications, Inc. All Rights Reserved.</p>
    </div>
  </footer>
</template>
