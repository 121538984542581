import { ref, unref, computed } from 'vue'

let reflect_apartment = ref(false)

export function useApartment() {

  return {
    reflect_apartment,
  }
}
