<template>
  <div class="position">

    <template v-if="id == 1">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>投稿内容を分類するためのテゴリを作成できます。カテゴリを分類しておけば、検索した際に該当トピックを見つけやすくなります。</p>
      </div>
    </template>

    <template v-else-if="id == 2">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>入力した内容がデジタルサイネージの画面に表示されます。<br><a href="https://help.m-cloud.jp/34" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 3">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>入力した内容がインターホンのMcloud画面に表示されます。<br><a href="https://help.m-cloud.jp/35" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 4">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 5">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>お知らせをいつからいつまでMcloud上に掲載しておくのかを設定できます。<br>掲載開始は指定した日付の0時～です。メール連絡で「する」を指定した場合、掲載開始日の朝9時にメールが配信されます。</p>
      </div>
    </template>

    <template v-else-if="id == 6">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>トップページの新着表示部分にお知らせを固定表示する設定です。<br>固定表示のお知らせ（最大3本）は、新着お知らせ（最新3本）の下に表示されます。</p>
      </div>
    </template>

    <template v-else-if="id == 9">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>googleカレンダーなどのカレンダーアプリで、「カレンダーを追加」にこのURLを登録することで、Mcloudの予定をカレンダーアプリに表示できます。<br><a href="https://help.m-cloud.jp/102" target="_blank">詳しくはこちら</a><br><br>※カレンダーアプリに登録した予定をMcloudに連携することはできません。</p>
      </div>
    </template>

    <template v-else-if="id == 10">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 11">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>Zoomを利用したビデオ会議の予定を登録できます。「する」を選択して予定を登録すると、会議開始10分前に「ビデオ会議を開始する」ボタンと利用に必要な「パスワード」が表示されます。<br><a href="https://22281644.fs1.hubspotusercontent-na1.net/hubfs/22281644/pdf/Mcloud_Video_Conference_Manual_v1.2.pdf" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 13">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>ファイル内の文字も検索対象に含めることができます。</p>
      </div>
    </template>

    <template v-else-if="id == 14">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 15">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>Mcloudにアップできるファイル容量の上限はフリープランは1GBまで、プレミアムプランは初期値10GBまでとなっております。プレミアムプランは容量アップが可能ですので、ご希望の場合はお問い合わせください。<br><a href="https://help.m-cloud.jp/129" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 16">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>アップロードできるファイルの種類は、jpg、gif、png、bmp、pdf、txt、doc、docx、xls、xlsx、ppt、pptx、zip の拡張子のファイルです。<br>各ファイルは最大10MBまでの容量制限があり、一度にアップできるファイルは最大30個で合計300MBまでになります。</p>
      </div>
    </template>

    <template v-else-if="id == 17">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 20">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>トピック（テーマ）ごとの進捗状況を「作業中」「確認待ち」「完了」の中から選択できます。別のステータスを作成することも可能です。</p>
      </div>
    </template>

    <template v-else-if="id == 21">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>投稿内容を分類するためのカテゴリを作成できます。カテゴリを分類しておけば、検索した際に該当トピックを見つけやすくなります。</p>
      </div>
    </template>

    <template v-else-if="id == 22">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 23">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>すでに投稿されているトピックに新しく添付ファイルを追加するときは、コメント入力欄からファイルを追加してください。</p>
      </div>
    </template>

    <template v-else-if="id == 24">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>本トピックに投稿があった場合に、メールでの通知を自分が受け取るかどうか設定できます。<br>「以下の時間を除いて通知を受信する」で設定した場合、その時間帯を避けて通知を受信します。</p>
      </div>
    </template>

    <template v-else-if="id == 25">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>匿名で投稿した場合でも、管理者には投稿者の投稿者名が公開されます。</p>
      </div>
    </template>

    <template v-else-if="id == 29">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 30">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>出欠確認に回答した後、回答期限までに再度、回答内容の変更を可能とするか不可とするか選択できます。</p>
      </div>
    </template>

    <template v-else-if="id == 31">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>回答期限の前に、他のメンバーの回答内容を閲覧可能とするか不可とするか選択できます。</p>
      </div>
    </template>

    <template v-else-if="id == 32">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>回答期限が過ぎたときに、全員の回答内容を閲覧可能とするか不可とするか選択できます。</p>
      </div>
    </template>

    <template v-else-if="id == 35">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 39">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>アンケート結果の総評としてレポート公開画面で表示されます。</p>
      </div>
    </template>

    <template v-else-if="id == 40">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>初期設定では「レポート公開しない」になっています。<br>「グループの設定」で作成したグループを指定することで、グループのメンバーのみ閲覧できるようになります。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 41">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>フリーアンサーの回答内容すべてが引用されます（回答を取得しないと表示されません）。引用した回答は、レポート用に編集してください。</p>
      </div>
    </template>

    <template v-else-if="id == 51">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>管理組合利用の場合に指定します。利用者画面に「管理組合利用」（変更可）と表示され、利用料金が0円になります。</p>
      </div>
    </template>

    <template v-else-if="id == 52">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「個人の設定」でお手持ちの鍵に色を指定してある場合、どの色の鍵で施設開錠するのか設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 53">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>入力した部屋番号の方が所持するご自宅の鍵でも、対象施設を開錠できるようになります。</p>
      </div>
    </template>

    <template v-else-if="id == 57">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>合計金額（受け取る料金の合計）を変更する場合に入力します。<br><a href="https://help.m-cloud.jp/51" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 59">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>この施設への予約申込みを一時的に停止したい場合、日時を登録することで、指定した期間中は受付を停止することができます。「外部協力」系の権限であれば、この期間中でも代理予約は可能です。<br><a href="https://help.m-cloud.jp/56" target="_blank">詳しくはこちら</a><br><br>※各施設ごとに設定が必要になります。</p>
      </div>
    </template>

    <template v-else-if="id == 60">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>この施設を一時的に利用不可としたい場合（施設の工事や、年末年始の休暇対応など）、この項目に日時を登録することで、指定したご利用日の予約受付をできなくすることができます。「外部協力」系の権限であれば、そのご利用日でも代理予約は可能です。<br><a href="https://help.m-cloud.jp/55" target="_blank">詳しくはこちら</a><br><br>※各施設ごとに設定が必要になります。</p>
      </div>
    </template>

    <template v-else-if="id == 61">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>施設を利用する上での条件やキャンセル方法などをご記入ください。入力した内容は予約フォームに表示されます。予約申込者は「利用規定に同意する」にチェックを付けないと予約の申し込みが出来ない仕組みになっています。</p>
      </div>
    </template>

    <template v-else-if="id == 62">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>居住者の方が使用細則で定められた申込書のPDFをダウンロードできるようになります。</p>
      </div>
    </template>

    <template v-else-if="id == 63">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「確認」のチェックを入れた予約を、予約状況一覧の上部に表示します。</p>
      </div>
    </template>

    <template v-else-if="id == 65">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>帳票とは、予約をされる方にお渡しする書面のことです。帳票内容の変更をご希望の方はお問い合わせください。<br><a href="https://kanri.m-cloud.jp/inquiries/new" target="_blank">お問い合わせはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 66">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>該当日時の当選者を自動で選ぶことができる機能です。<br><a href="https://help.m-cloud.jp/49" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 67">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>窓口でお支払い済みの金額が表示されます。<br>部屋づけ君で決済した場合は、金額の後ろに決済方法の情報が表示されます。</p>
      </div>
    </template>

    <template v-else-if="id == 74">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>Mcloudを操作できる内容は権限ごとに異なります。<br><a href="https://help.m-cloud.jp/289" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 75">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>ご自身が所属しているグループです。<br>お知らせ、カレンダー、データ保存箱など各機能の登録内容はグループごとに閲覧の可否が設定されており、所属するグループの投稿のみ閲覧できます。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 77">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>鍵の設定をしておくことで、該当の鍵を持った方が帰宅した際に、通知メールを配信することができます。</p>
      </div>
    </template>

    <template v-else-if="id == 78">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>鍵の設定をしておくことで、該当の鍵を持った方が外出した際に、通知メールを配信することができます。</p>
      </div>
    </template>

    <template v-else-if="id == 83">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「利用する」に設定すると、管理者が承認した投稿のみ掲示板に表示されるようになります。</p>
      </div>
    </template>

    <template v-else-if="id == 84">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>ご意見箱の投稿ページの上部に、説明文を入れることができます。</p>
      </div>
    </template>

    <template v-else-if="id == 89">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>アカウントを利用される方の立場を設定できます。<br>
          区分所有者：居住しているオーナーの方<br>
          外部区分所有者：居住していないオーナーの方<br>
          賃借人：居住している賃貸の方<br>
          協力会社：管理会社、管理員、コンシェルジュの方</p>
      </div>
    </template>

    <template v-else-if="id == 90">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>理事会やサークルなどで役職がある場合に、任意の役職を作成して指定することができます。</p>
      </div>
    </template>

    <template v-else-if="id == 91">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>
          Mcloudを操作できる内容は権限ごとに異なります。<br>
          主に理事長様や管理会社担当者様には、「管理者マスター」または「管理者A」の権限をご利用いただき、他の理事会役員様や管理員様には「管理者B」の権限をご利用いただくケースが多いです。<br>
          <a href="https://help.m-cloud.jp/289" target="_blank">詳しくはこちら</a>
        </p>
      </div>
    </template>

    <template v-else-if="id == 92">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>お知らせ、カレンダー、データ保存箱など各機能の登録内容はグループごとに閲覧の可否が設定されています。<br>新メンバーが所属するグループを選択してください。<br><a href="https://help.m-cloud.jp/196" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 93">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>
          アカウントを利用される方の立場を設定できます。<br>
          区分所有者：居住している分譲で購入した方<br>
          外部区分所有者：居住していない外部オーナーの方<br>
          賃借人：居住している賃貸の方<br>
          協力会社：管理会社、管理員、コンシェルジュの方
        </p>
      </div>
    </template>

    <template v-else-if="id == 94">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>
          Mcloudを操作できる内容は権限ごとに異なります。<br>
          主に理事長様や管理会社担当者様には、「管理者マスター」または「管理者A」の権限をご利用いただき、他の理事会役員様や管理員様には「管理者B」の権限をご利用いただくケースが多いです。<br>
          <a href="https://help.m-cloud.jp/289" target="_blank">詳しくはこちら</a>
        </p>
      </div>
    </template>

    <template v-else-if="id == 95">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>
          管理者アカウントの場合、「理事アカウントにする」の項目には必ずチェックを入れてください。<br>
          ※「理事アカウントにする」の項目は、管理者マスター、外部協力マスター権限のみ表示されます。<br>
          ※居住者アカウント作成の場合、「理事アカウントにする」の項目はチェック不要です。
        </p>
      </div>
    </template>

    <template v-else-if="id == 99">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>グループを選択していただくと、グループメンバーが参加者として登録されます。</p>
      </div>
    </template>

    <template v-else-if="id == 100">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>過去に議論して継続になっている場合など、今回の会議であらためて議論する必要がある議題を選択してください。</p>
      </div>
    </template>

    <template v-else-if="id == 103">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>宿題に対応したら、該当の宿題を選択してコメントを投稿してください。議事メモに追加されます。<br>投稿されたコメントは内容別に絞り込んで表示できます。<br></p>
      </div>
    </template>

    <template v-else-if="id == 104">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>宿題名、担当者、期限、スタータスなど登録した情報を担当者宛にメール配信します。</p>
      </div>
    </template>

    <template v-else-if="id == 111">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>使用細則で定められた申込書のPDFをダウンロードできます。</p>
      </div>
    </template>

    <template v-else-if="id == 112">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>外部協力権限のアカウントで、内容を編集することができます。</p>
      </div>
    </template>

    <template v-else-if="id == 116">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>トップページ右側に表示されるバナーリンクを設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 117">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>トップページ右下に表示される関連サイトのバナーリンクを設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 118">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>トップページ右下に表示される関連サイトのテキストリンクを設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 119">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>「システム作成）理事会」のグループを利用する設定です。「理事用アカウント」が「理事」で設定されているアカウントが自動でグルーピングされます。</p>
      </div>
    </template>

    <template v-else-if="id == 120">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>次の4つのグループ「システム作成）区分所有者」「システム作成）賃借人」「システム作成）外部区分所有者」「システム作成）協力会社」を利用する設定です。<br>それぞれに該当するアカウントが自動でグルーピングされます。</p>
      </div>
    </template>

    <!-- MCK_RE-1371 施設予約設定機能で追加 -->
    <template v-else-if="id == 130">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>施設の提供方法を宿泊利用（ゲストルームなど泊数単位で申込む施設用）や時間貸し（駐車場や集会室など開始と終了時間を自由に選択して申込む施設用）、コマ貸し（開始と終了時間があらかじめ決められたコマ単位で申込む施設用）の３種から選択する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 131">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>施設のOPEN時間や料金などに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 132">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約の受付開始日や受付終了日などに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 133">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>申込停止や施設利用不可期間などの受付の制限に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 134">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約に対する入金期限や入金依頼メールなどに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 135">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約の回数制限に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 136">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>連続して利用できる上限に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 137">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>１予約で利用できる人数の上限に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 138">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>オンラインでのキャンセルに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 139">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約フォームに表示する項目などに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 140">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>利用規定に表示する文面などに関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 141">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>各種メールと各種通知書の雛形に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 142">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>利用条件のページで表示する内容に関する設定です。</p>
      </div>
    </template>

    <template v-else-if="id == 143">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>複数の利用形態を設定するケースや曜日に応じて設定が異なるケースなどの複雑な設定事例はユーザーサポートをご確認ください。<br><a href="https://help.m-cloud.jp/307/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 144">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付期間に関する詳しい設定事例はユーザーサポートをご確認ください。<br><a href="https://help.m-cloud.jp/308/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 145">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>最高料金の詳しい設定事例はユーザーサポートをご確認ください。<br><a href="https://help.m-cloud.jp/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 146">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付期間 終了日時：システム制御上、必要な項目です。基本的には「当月 末日 24:00」を設定してください。<br>※受付終了日時に設定する内容よりも早い日時を入れてしまうと、その日に受付を終了してしまいます</p>
      </div>
    </template>

    <template v-else-if="id == 147">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付終了日時：利用日に対して、「●日前の●●時まで」または「利用開始時刻の●分前まで」を設定してください。<br>
          例１）利用日の2日前の19時00分までを設定した場合、1月10日利用分への申込みは、1月8日19:00で受付を終了します。<br>
          例２）利用日の0日前の23:59分までを設定した場合、1月10日15時からの利用分への申込みは、当日の15時直前まで受け付けます。<br>
          例３）利用開始時刻の10分前に受付終了を設定した場合、1月10日15時からの利用分への申込みは、当日の14時50分まで受け付けます。</p>
      </div>
    </template>

    <template v-else-if="id == 148">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付期間 開始日時：予約の受付を開始する日時を入力してください。抽選方式であれば抽選受付開始日時、先着方式であれば先着受付開始日時になります。</p>
      </div>
    </template>

    <template v-else-if="id == 149">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付期間 終了日時：予約の受付を終了する日時を入力してください。抽選方式であれば抽選受付終了日時、先着方式であれば先着受付終了日時になります</p>
      </div>
    </template>

    <template v-else-if="id == 150">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>この施設への予約申込みを一時的に停止したい場合、日時を登録することで、指定した期間中は受付を停止することができます。「外部協力」系の権限であれば、この期間中でも代理予約は可能です。<br><a href="https://help.m-cloud.jp/309/" target="_blank">詳しくはこちら</a><br><br>※各施設ごとに設定が必要になります。</p>
      </div>
    </template>

    <template v-else-if="id == 151">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>この施設を一時的に利用不可としたい場合（施設の工事や、年末年始の休暇対応など）、この項目に日時を登録することで、指定したご利用日の予約受付をできなくすることができます。「外部協力」系の権限であれば、そのご利用日でも代理予約は可能です。<br><a href="https://help.m-cloud.jp/309/" target="_blank">詳しくはこちら</a><br><br>※各施設ごとに設定が必要になります。</p>
      </div>
    </template>

    <template v-else-if="id == 152">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>詳しい設定事例はユーザーサポートをご確認ください<br><a href="https://help.m-cloud.jp/310/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 153">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>ここで選択する日付は、予約を登録する日に対してではなく、ご利用希望日に対して適用されます。適用期間に期限を設けたい場合は、「終了日を指定する」をクリックして表示されるフォームに終了年月日を入力してください。<br><a href="https://help.m-cloud.jp/312/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 154">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>※年は、1月1日～12月31日でカウントします。</p>
      </div>
    </template>

    <template v-else-if="id == 155">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>※抽選受付期間中の申込み回数を制限します。先着受付期間に入ってからの回数制限は、「利用回数制限」で設定してください。</p>
      </div>
    </template>

    <template v-else-if="id == 156">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>※Mcloudでは窓口での代理予約などを入力するケースもあるため、回数のカウントはアカウント単位ではなく部屋番号単位となります。例えば、「年●回」の回数制限において、その期間中に入居者の入れ替えがあった場合、前の居住者の利用回数は、その期間が終了するまではリセットされずカウントされるのでご注意ください。
          <br>※外部協力権限での代理予約であれば制限回数を超えても「強制予約」が可能です。</p>
      </div>
    </template>

    <template v-else-if="id == 157">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>※例えば、３時間までの制限で「集約しない」を選択した場合、10時～13時までの予約と13時～16時までという２つの連続した予約を許容します。 ３時間までの制限で「集約する」を選択した場合、10時～12時までの予約登録後、12時～14時の予約を登録しようとすると、10時からの時間と合計して３時間を超えるので、制限にひっかかり予約を登録できなくなります。<br><a href="https://help.m-cloud.jp/311/" target="_blank">⇒もっと詳しい説明</a></p>
      </div>
    </template>

    <template v-else-if="id == 158">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>受付期間 開始日時：予約の受付を開始する日時を入力してください。抽選方式であれば抽選受付開始日時、先着方式であれば先着受付開始日時になります</p>
      </div>
    </template>

    <template v-else-if="id == 159">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>切上げ・切捨ての計算をする時に、０時で区切って計算をしています。日をまたぐ予約が出来る場合は、開始時間の設定と基本料金の単位を合わせるか、基本最高料金の２４時間単位で金額を指定してください。</p>
      </div>
    </template>

    <template v-else-if="id == 160">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>アップロードできるファイル形式は、PDF、Word、Excelのいずれかになります。</p>
      </div>
    </template>

    <template v-else-if="id == 161">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>適用期間に期限を設けたい場合は、「終了日を指定する」をクリックして表示されるフォームに終了年月日を入力してください。<br><a href="https://help.m-cloud.jp/312/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 162">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約時に必ず入力してもらいたい項目は「必須入力」、入力は任意で良いという項目は「任意入力」、予約フォームに表示しない項目は「非表示」を選択してください。</p>
      </div>
    </template>

    <template v-else-if="id == 163">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>仮予約はいつでもキャンセル可能です。確定予約の予約に対して、利用日の何日前までキャンセル可能とするかを設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 164">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>仮予約はいつでもキャンセル可能です。予約が確定した日（先着期間の申し込み日、抽選で当選した日、承認された日）から何日後までキャンセル可能とするかを設定できます。</p>
      </div>
    </template>

    <template v-else-if="id == 165">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>返金手続きなどの都合上、入金済みの予約は窓口での対応に限定したい場合などに「不可」を選択してください。居住者権限でキャンセル不可となります。※外部協力権限ではキャンセル可能です。</p>
      </div>
    </template>

    <template v-else-if="id == 166">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>複数施設に跨って利用回数の制限を設定できます。例えば、駐車場が３区画あった場合、どの駐車場を使ったとしても合計月Ｎ回までといった制限が可能です。<br>
        ドロップダウンの中に使いたい「グループ回数制限」が無ければ、「グループの回数制限を編集する」をクリックして新規作成してください。その後、各施設の「グループ回数制限」の設定で、その「グループ回数制限」をドロップダウンから選択し、設定してください。</p>
      </div>
    </template>

    <template v-else-if="id == 167">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>設定適用期間が重なる場合は、並び順が上位に来ている設定が採用されます。</p>
      </div>
    </template>

    <template v-else-if="id == 168">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>コピーをクリックすると、その適用期間に設定されている利用時間や料金などの設定内容もコピーできます。</p>
      </div>
    </template>

    <template v-else-if="id == 169">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>通常は「停止しない」を選択してください。複数の「時間と料金」を設定する様な特殊な設定をする場合に「停止する」が必要になるケースがあります。<br><a href="https://help.m-cloud.jp/307/" target="_blank">詳しくはこちら</a></p>
      </div>
    </template>

    <template v-else-if="id == 170">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>一度設定した利用形態が「時間と料金」の設定に使われていたり、すでに予約データが存在する場合は、その利用形態を変更・削除できません。<br>まだ予約データが無い状態であれば、「時間と料金」の設定を削除することで、利用形態の編集が可能になります。<br>もし予約データがすでにある場合は、その施設は非公開にして、新しい施設を追加してください。</p>
      </div>
    </template>

    <template v-else-if="id == 171">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>利用形態、時間と料金、受付期間、利用規定の４項目は設定の登録が必要です。</p>
      </div>
    </template>

    <template v-else-if="id == 172">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>年は、1月1日～12月31日でカウントします。</p>
      </div>
    </template>

    <template v-else-if="id == 173">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>抽選予約受付期間に、予約の申込をした時点で配信されるメールです。<br>※先着受付期間には配信されません。</p>
      </div>
    </template>

    <template v-else-if="id == 174">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約が確定したときに配信されるメールです。<br>※先着受付期間で申込をした時点、または、抽選実施後、予約が当選した時点で配信されます。</p>
      </div>
    </template>

    <template v-else-if="id == 175">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約をキャンセルしたときに配信されるメールです。</p>
      </div>
    </template>

    <template v-else-if="id == 176">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>施設利用料の支払いを依頼するメールです。設定した任意の日時に配信されます。</p>
      </div>
    </template>

    <template v-else-if="id == 177">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>日時、人数、条件など予約内容を変更したときに配信されるメールです。</p>
      </div>
    </template>

    <template v-else-if="id == 178">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>入金処理をした時に配信されます。</p>
      </div>
    </template>

    <template v-else-if="id == 179">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>抽選予約で、抽選に外れてしまったときに配信されるメールです。</p>
      </div>
    </template>

    <template v-else-if="id == 180">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>※平日と休日に関係なく連続制限を設けたい場合は、「平日の連続制限（宿泊）」と「休日の連続制限（宿泊）」の両方に同じ数字を入れてください。</p>
      </div>
    </template>

    <template v-else-if="id == 181">
      <a class="help_link" v-on:click="toggleTooltip">？</a>
      <div class="status_box" v-bind:class="[className]">
        <p>予約の申込者が選択した開始日時に対応する「時間と料金」の設定にあるオプションの内容が採用されます。</p>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['value', 'id', 'className'],
  data: function () {
    return {
      error: null,
      data: null,
    }
  },
  methods: {
    init: function () {
      //document.body.style.overflow = 'hidden';
    },
    toggleTooltip: function (e) {
      e.currentTarget.classList.toggle('open');
    },
  },
  mounted: function () {
    this.init();
  },
  watch: {
    '$route': function () {
      //this.closeModal();
    }
  },
}
</script>
<style scoped>
a.help_link {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  margin: 0;
  background: #ffb500;
  display: inline-block;
  position: relative;
  top: -2px;
}
</style>
