<template>
  <ModalBase :is-show="isOpen" style-class="modal_info_bg" @close="closeModal()">
    <template #content>
      <div class="bg">
        <h1 class="help_modal_title">
          <icon name="ico-info" class="svg_info" viewBox="0 0 24.08 24.36"></icon>つなぐネットからのお知らせ
        </h1>
        <div v-if="error" class="errortop">
          <p v-for="(message, i) in error.messages" :key="i">{{ message }}</p>
        </div>
        <div v-if="data">
          <div
            v-for="notice in data.filter(data => (data.attributes.status == 1 && data.attributes.display != 1 && data.attributes.kind != 3 && data.attributes.kind != 4))"
            :key="notice.id" class="top_info_list cf" :class="{
              pc_only: notice.attributes.target_display_pc && !notice.attributes.target_display_sma,
              sp_only: !notice.attributes.target_display_pc && notice.attributes.target_display_sma,
            }">
            <div v-if="notice.attributes.banner_url" class="image">
              <a v-if="notice.attributes.url" :href="notice.attributes.url" target="_blank"><img
                  :src="notice.attributes.banner_url" :alt="notice.attributes.title"></a>
              <img v-else :src="notice.attributes.banner_url" :alt="notice.attributes.title">
            </div>
            <p v-else-if="notice.attributes.uploaded_file_url"><a :href="notice.attributes.uploaded_file_url"
                target="_blank">{{ notice.attributes.title }}</a></p>
            <p v-else-if="notice.attributes.url"><a :href="notice.attributes.url" target="_blank">{{
              notice.attributes.title }}</a></p>
            <p v-else>{{ notice.attributes.title }}</p>
          </div>
          <div v-if="data.filter(data => (data.attributes.status == 1 && data.attributes.display != 1)).length == 0"
            class="top_info_list cf">
            <p>お知らせがありません。</p>
          </div>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/modal-base.vue'
import { useModal } from '@/composables/modal'

export default {
  components: { ModalBase },
  setup(props, { expose }) {
    // モーダル制御
    const { openModal, closeModal, isOpen, onOpen } = useModal()

    // モーダル利用画面からの表示用
    expose({ openModal })

    // this.アクセス用 template, optionsへの公開
    return { isOpen, closeModal, onOpen }
  },
  data: function () {
    return {
      data: null,
      error: null,
    }
  },
  mounted: function () {
    this.onOpen(() => {
      this.error = null
      this.get('/admin_notices', function (res) {
        this.data = res.detail.data
        this.error = res.detail.error
      })
    })
  }
}
</script>
