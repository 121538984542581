import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'ホーム', group: 'toppage' },
    component: () => import('../views/home.vue')
  },
  {
    // 会議管理
    path: '/board/',
    name: 'B-1-1',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/index.vue')
  },
  {
    // 会議の新規作成
    path: '/board/new',
    name: 'B-1-2',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/input.vue')
  },
  {
    // 会議編集
    path: '/board/:id(\\d+)/:pathMatch(edit)',
    name: 'B-1-2edit',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/input.vue')
  },
  {
    // 会議のコピー
    path: '/board/:id(\\d+)/:pathMatch(clone)',
    name: 'B-1-2clone',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/input.vue')
  },
  {
    // 会議詳細
    path: '/board/:id(\\d+)',
    name: 'B-1-3',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/leaf.vue')
  },
  {
    // 議題を追加する
    path: '/board/:id/agendas/new',
    name: 'B-1-4',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/agendas/input.vue')
  },
  {
    // 議題を編集する
    path: '/board/agendas/:aid(\\d+)/:pathMatch(edit)',
    name: 'B-1-4edit',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/agendas/input.vue')
  },
  {
    // 議題のコピー
    path: '/board/agendas/:aid(\\d+)/:pathMatch(clone)',
    name: 'B-1-4clone',
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/agendas/input.vue')
  },
  {
    // アジェンダ・議事録案の登録
    path: '/board/:id/proceedings/',
    name: 'B-1-6',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/proceedings/input.vue')
  },
  {
    // 議事録に記載する内容を編集する
    path: '/board/:id(\\d+)/proceedings/:aid(\\d+)/comments',
    name: 'B-1-7',
    props: true,
    meta: { title: '', group: 'conferencepage', },
    component: () => import('../views/board/proceedings/comment.vue')
  },
  {
    // 印刷プレビュー（議事録案）
    path: '/board/:id/proceedings/confirm',
    name: 'B-1-8',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/proceedings/confirm.vue')
  },
  {
    // 議題一覧
    path: '/board/agendas/',
    name: 'B-1-10',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/agendas/index.vue')
  },
  {
    // 議題詳細
    path: '/board/agendas/:id(\\d+)',
    name: 'B-1-11',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/agendas/leaf.vue')
  },
  {
    // 宿題を編集する
    path: '/board/tasks/:id(\\d+)/edit',
    name: 'B-1-12',
    props: true,
    meta: { title: '会議管理', group: 'conferencepage', },
    component: () => import('../views/board/tasks/input.vue')
  },
  {
    // マンションの設定
    path: '/apartments/',
    name: 'B-2-1',
    props: true,
    meta: { title: 'マンションの設定', group: 'apartmentspage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/index.vue')
  },
  {
    // マンションの設定
    path: '/apartments/edit',
    name: 'B-2-2',
    meta: { title: 'マンションの設定', group: 'apartmentspage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/input.vue')
  },
  {
    // コンテンツの設定
    path: '/apartments/contents/',
    name: 'B-2-3',
    props: true,
    meta: { title: 'コンテンツの設定', group: 'contentspage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/contents/index.vue')
  },
  {
    // コンテンツの設定（入力）
    path: '/apartments/contents/edit',
    name: 'B-2-4',
    meta: { title: 'コンテンツの設定', group: 'contentspage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/contents/input.vue')
  },
  {
    // バナー・関連リンクの設定
    path: '/apartments/associate_links/',
    name: 'B-2-11',
    props: true,
    meta: { title: 'バナー・関連リンクの設定', group: 'bannerpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/associate_links/index.vue')
  },
  {
    // 「バナー画像」の登録
    path: '/apartments/associate_links/:pathMatch(banner)',
    name: 'B-2-5',
    meta: { title: 'バナー・関連リンクの設定', group: 'bannerpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/associate_links/input.vue')
  },
  {
    // 関連サイト「テキストリンク」の登録
    path: '/apartments/associate_links/:pathMatch(text)',
    name: 'B-2-6',
    meta: { title: 'バナー・関連リンクの設定', group: 'bannerpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/associate_links/input.vue')
  },
  {
    // 関連サイト「バナー画像」の登録
    path: '/apartments/associate_links/:pathMatch(image)',
    name: 'B-2-7',
    meta: { title: 'バナー・関連リンクの設定', group: 'bannerpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/associate_links/input.vue')
  },
  {
    // バナー・関連リンクの編集
    path: '/apartments/associate_links/:id(\\d+)/:pathMatch(edit)',
    name: 'B-2-11edit',
    meta: { title: 'バナー・関連リンクの設定', group: 'bannerpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/apartments/associate_links/input.vue')
  },
  {
    // 個人の設定
    path: '/account/',
    name: 'B-3-11',
    props: true,
    meta: { title: '個人の設定', group: 'userspage', },
    component: () => import('../views/account/index.vue')
  },
  {
    // 旧個人の設定
    path: '/users/:id(\\d+)',
    redirect: to => {
      return {name: 'B-3-11'}
    },
  },
  {
    // 個人登録情報の編集
    path: '/account/edit',
    name: 'B-3-12',
    meta: { title: '個人の設定', group: 'userspage', },
    component: () => import('../views/account/input.vue')
  },
  {
    // 個人登録情報の編集
    path: '/account/room_ekey_notice/edit',
    name: 'B-3-12_key',
    meta: { title: '個人の設定', group: 'userspage', },
    component: () => import('../views/account/room_ekey_notice/input.vue')
  },
  {
    // メールアドレスの変更
    path: '/account/change_email/',
    name: 'B-3-13',
    meta: { title: '個人の設定', group: 'userspage', },
    component: () => import('../views/account/change_email/input.vue')
  },
  {
    // メールアドレスの変更(送信)
    path: '/account/change_email/send',
    name: 'B-3-14',
    meta: { title: '個人の設定', group: 'userspage', login: 'all' },
    component: () => import('../views/account/change_email/send.vue')
  },
  {
    // メールアドレスの変更(完了)
    path: '/users/change_email_complete',
    name: 'B-3-17',
    meta: { title: '個人の設定', group: 'userspage', },
    component: () => import('../views/account/change_email/complete.vue')
  },
  {
    // お知らせ一覧
    path: '/notices/',
    name: 'B-4-1',
    props: true,
    meta: { title: 'お知らせ', group: 'informationpage', use: 'use_notice' },
    component: () => import('../views/notices/index.vue')
  },
  {
    // お知らせ登録
    path: '/notices/new',
    name: 'B-5-1',
    meta: { title: 'お知らせ', group: 'informationpage', use: 'use_notice', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/notices/input.vue')
  },
  {
    // お知らせをコピーして登録
    path: '/notices/:pathMatch(clone)/:id',
    name: 'B-5-1clone',
    meta: { title: 'お知らせ', group: 'informationpage', use: 'use_notice', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/notices/input.vue')
  },
  {
    // お知らせ編集
    path: '/notices/:id/:pathMatch(edit)',
    name: 'B-5-1edit',
    meta: { title: 'お知らせ', group: 'informationpage', use: 'use_notice', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/notices/input.vue')
  },
  {
    // お知らせ詳細
    path: '/notices/:id',
    name: 'B-4-2',
    props: true,
    meta: { title: 'お知らせ', group: 'informationpage', use: 'use_notice' },
    component: () => import('../views/notices/leaf.vue')
  },
  {
    // カレンダー今月
    path: '/events/',
    name: 'B-6-1',
    props: true,
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/index.vue')
  },
  {
    // カレンダー月別
    path: '/events/:y(\\d+)/:m(\\d+)',
    name: 'B-6-1monthly',
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/index.vue')
  },
  {
    // カレンダー日別
    path: '/events/:y(\\d+)/:m(\\d+)/:d(\\d+)',
    name: 'B-6-1daily',
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/index.vue')
  },
  {
    // 予定詳細
    path: '/events/:id(\\d+)',
    name: 'B-7-1',
    props: true,
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/leaf.vue')
  },
  {
    // カレンダー 予定の新規作成
    path: '/events/:y/:m/:d/new',
    name: 'B-7-2',
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/input.vue')
  },
  {
    // 予定の編集
    path: '/events/:id(\\d+)/:pathMatch(edit)',
    name: 'B-7-2edit',
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/input.vue')
  },
  {
    // 予定の編集
    path: '/events/:id(\\d+)/:pathMatch(clone)',
    name: 'B-7-2clone',
    meta: { title: 'カレンダー', group: 'calendarpage', use: 'use_calendar' },
    component: () => import('../views/events/input.vue')
  },
  {
    // データ保存箱
    path: '/resources/:id(\\d*)',
    name: 'B-8-1',
    props: true,
    meta: { title: 'データ保存箱', group: 'datapage' },
    component: () => import('../views/resources/index.vue')
  },
  {
    // データ保存箱検索
    path: '/resources/search',
    name: 'B-8-2',
    props: true,
    meta: { title: 'データ保存箱', group: 'datapage' },
    component: () => import('../views/resources/search.vue')
  },
  {
    // データ保存箱 ファイル詳細 旧URL
    path: '/resources/show_item/:id(\\d+)',
    redirect: to => {
      return {name: 'B-9-3', params: to.params}
    },
  },
  {
    // データ保存箱 ファイル詳細
    path: '/resources/item/:id(\\d+)',
    name: 'B-9-3',
    props: true,
    meta: { title: 'データ保存箱', group: 'datapage' },
    component: () => import('../views/resources/file/leaf.vue')
  },
  {
    // データ保存箱 ファイル編集
    path: '/resources/item/:item_id(\\d+)/edit',
    name: 'B-9-1edit',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/resources/file/input.vue')
  },
  {
    // データ保存箱 ファイル移動
    path: '/resources/:pathMatch(item)/:id(\\d+)/move',
    name: 'B-9-2b',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/file/move.vue')
  },
  {
    // データ保存箱 ファイルアップロード
    path: '/resources/item/new',
    name: 'B-9-1-root',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/resources/file/input.vue')
  },
  {
    // データ保存箱 ファイルアップロード
    path: '/resources/:id(\\d*)/item/new',
    name: 'B-9-1',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/resources/file/input.vue')
  },
  {
    // データ保存箱 フォルダ作成
    path: '/resources/:id(\\d*)/new',
    name: 'B-9-2a',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/folder/input.vue')
  },
  {
    // データ保存箱 フォルダ作成
    path: '/resources/new',
    name: 'B-9-2new',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/folder/input.vue')
  },
  {
    // データ保存箱 フォルダ編集
    path: '/resources/:id(\\d*)/:pathMatch(edit)',
    name: 'B-9-2aedit',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/folder/input.vue')
  },
  {
    // データ保存箱 フォルダ移動
    path: '/resources/:id(\\d*)/move',
    name: 'B-9-2c',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/folder/move.vue')
  },
  {
    // データ保存箱 まとめて操作
    path: '/resources/batch/:id(\\d*)',
    name: 'B-9-2d',
    meta: { title: 'データ保存箱', group: 'datapage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/resources/batch.vue')
  },
  {
    // 施設予約 予約状況（月）
    path: '/facility/monthly/:y/:m',
    name: 'B-14-3',
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/monthly.vue')
  },
  {
    // 施設予約
    path: '/facility/',
    name: 'B-14-1',
    props: true,
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/daily.vue')
  },
  {
    // 施設予約 予約状況（日）
    path: '/facility/daily/:y/:m/:d',
    name: 'B-14-2',
    props: true,
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/daily.vue')
  },
  {
    // 施設予約 予約状況（今週）
    path: '/facility/weekly/',
    name: 'B-14-11',
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/weekly.vue')
  },
  {
    // 施設予約 予約状況（週）
    path: '/facility/weekly/:y/:m/:d',
    name: 'B-14-11b',
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/weekly.vue')
  },
  {
    // 施設予約 入力
    path: '/facility/reservations/new/:id/:y/:m/:d',
    name: 'B-14-4',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13] },
    component: () => import('../views/facility/reservations/input.vue')
  },
  {
    // 施設予約 確認
    path: '/facility/reservations/confirm',
    name: 'B-14-4confirm',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13] },
    component: () => import('../views/facility/reservations/confirm.vue')
  },
  {
    // 施設予約 予約履歴
    path: '/facility/reservations/',
    name: 'B-13-1',
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/reservations/index.vue')
  },
  {
    // 施設予約 予約検索
    path: '/facility/reservations/search',
    name: 'B-14-7',
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/reservations/search.vue')
  },
  {
    // 施設予約 予約抽選
    path: '/facility/reservations/lottery/:id',
    name: 'B-14-10',
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13], disabled: { search: true } },
    component: () => import('../views/facility/reservations/lottery.vue')
  },
  {
    // 施設予約 予約詳細
    path: '/facility/reservations/:id',
    name: 'B-14-8',
    props: true,
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/reservations/leaf.vue')
  },
  {
    // 施設予約 予約編集
    path: '/facility/reservations/:id/edit',
    name: 'B-14-8edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/reservations/edit.vue')
  },
  {
    // 支払い明細
    path: '/payments/',
    name: 'B-14-13',
    meta: { title: 'ご利用明細', group: 'reservpage' },
    component: () => import('../views/payments/index.vue')
  },
  {
    // 施設予約 施設一覧
    path: '/facility/services/',
    name: 'B-12-1',
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/services/index.vue')
  },
  {
    // 施設予約 施設詳細
    path: '/facility/services/:id',
    name: 'B-12-2',
    props: true,
    meta: { title: '施設予約', group: 'reservpage' },
    component: () => import('../views/facility/services/leaf.vue')
  },
  {
    // 施設予約 施設詳細
    path: '/facility/services/:id/detail',
    name: 'facility-services-detail',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/detail.vue')
  },
  {
    // 施設予約 施設の基本情報の作成
    path: '/facility/services/detail/create',
    name: 'facility-services-detail-create',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/basic/edit.vue')
  },
  {
    // 施設予約 施設の基本情報の編集
    path: '/facility/services/:id(\\d+)/detail/:pathMatch(edit)',
    name: 'facility-services-detail-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/basic/edit.vue')
  },
  {
    // 施設予約 利用形態編集
    path: '/facility/services/:id/service_uses/edit',
    name: 'facility-services-service_uses-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/service_uses/edit.vue')
  },
  {
    // 施設予約 時間と料金(新規作成)
    path: '/facility/services/:service_id/price_details',
    name: 'facility-services-prices-price_details',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/price_details/edit.vue')
  },
  {
    // 施設予約 時間と料金(編集)
    path: '/facility/services/:service_id/prices/:price_id/price_details/:id/edit',
    name: 'facility-services-prices-price_details-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/price_details/edit.vue')
  },
  {
    // 施設予約 利用規定編集
    path: '/facility/services/:id/rules/edit',
    name: 'facility-services-rules-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/rules/edit.vue')
  },
  {
    // 施設予約 予約フォームの新規作成
    path: '/facility/services/:id/booking_forms/new',
    name: 'facility-services-booking_forms-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/booking_forms/input.vue')
  },
  {
    // 施設予約 予約フォームの編集
    path: '/facility/services/:id/booking_forms/:fid(\\d+)/:pathMatch(edit)',
    name: 'facility-services-booking_forms-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/booking_forms/input.vue')
  },
  {
    // 施設予約 受付期間(予約方式)の新規作成
    path: '/facility/services/:id/booking_types/new',
    name: 'facility-services-booking_types-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/booking_types/input.vue')
  },
  {
    // 施設予約 受付期間(予約方式)の編集
    path: '/facility/services/:id/booking_types/:fid(\\d+)/:pathMatch(edit)',
    name: 'facility-services-booking_types-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/booking_types/input.vue')
  },
  {
    // 施設予約 受付制限
    path: '/facility/services/:id/limit_reception',
    name: 'facilty-services-limitreception',
    props: true,
    meta: { title: '受付制限', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_reception.vue')
  },
  {
    // 施設予約 メール通知書編集
    path: '/facility/services/:id/mails/edit',
    name: 'facility-services-mails-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/mails/edit.vue')
  },
  {
    // 施設予約 利用制限(入金) 新規
    path: '/facility/services/:id/limit_deposits/new',
    name: 'facility-services-limit_deposits-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_deposits/edit.vue')
  },
  {
    // 施設予約 利用制限(入金)　編集
    path: '/facility/services/:id/limit_deposits/:limit_deposit_id(\\d+)/:pathMatch(edit)',
    name: 'facility-services-limit_deposits-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_deposits/edit.vue')
  },
  {
    // 施設予約 利用制限(回数) 新規
    path: '/facility/services/:id/limit_numbers/new',
    name: 'facility-services-limit_numbers-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_numbers/edit.vue')
  },
  {
    // 施設予約 利用制限(回数)　編集
    path: '/facility/services/:id/limit_numbers/:limit_number_id(\\d+)/:pathMatch(edit)',
    name: 'facility-services-limit_numbers-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_numbers/edit.vue')
  },
  {
    // 施設予約 グループ回数制限　編集
    path: '/facility/services/:id/service_limits/:pathMatch(edit)',
    name: 'facility-services-service_limits-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/service_limits/edit.vue')
  },
  {
    // 施設予約 利用制限(連続) 新規
    path: '/facility/services/:id/limit_continues/new',
    name: 'facility-services-limit_continues-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_continues/edit.vue')
  },
  {
    // 施設予約 利用制限(連続)　編集
    path: '/facility/services/:id/limit_continues/:limit_continue_id(\\d+)/:pathMatch(edit)',
    name: 'facility-services-limit_continues-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_continues/edit.vue')
  },
  {
    // 施設予約 利用制限(人数) 新規
    path: '/facility/services/:id/limit_counts/new',
    name: 'facility-services-limit_counts-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_counts/edit.vue')
  },
  {
    // 施設予約 利用制限(人数)　編集
    path: '/facility/services/:id/limit_counts/:limit_count_id(\\d+)/:pathMatch(edit)',
    name: 'facility-services-limit_counts-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_counts/edit.vue')
  },
  {
    // 施設予約 キャンセル 新規
    path: '/facility/services/:id/limit_cancels/new',
    name: 'facility-services-limit_cancels-new',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_cancels/edit.vue')
  },
  {
    // 施設予約 キャンセル　編集
    path: '/facility/services/:id/limit_cancels/:limit_cancel_id(\\d+)/:pathMatch(edit)',
    name: 'facility-services-limit_cancels-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/limit_cancels/edit.vue')
  },
  {
    // 施設予約 利用条件のご案内 編集
    path: '/facility/services/:id/service_description/:pathMatch(edit)',
    name: 'facility-services-service_description-edit',
    props: true,
    meta: { title: '施設予約', group: 'reservpage', roles: [1, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/facility/services/description/edit.vue')
  },
  // アンケート
  {
    path: '/survey/',
    name: 'B-15-1',
    props: true,
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire' },
    component: () => import('../views/survey/index.vue')
  },
  // アンケート詳細旧URL
  {
    path: '/survey/result_sheets/:id(\\d+)',
    redirect: to => {
      return {name: 'B-15-2', params: to.params}
    },
  },
  // アンケート詳細旧URL
  {
    path: '/survey/result_sheets/new',
    redirect: to => {
      return {name: 'B-15-2', params: {id: to.query.qid}, query: ''}
    },
  },
  // アンケート詳細旧URL
  {
    path: '/survey/proxy_result_sheets/preview',
    redirect: to => {
      return {name: 'B-15-2', params: {id: to.query.qid}, query: ''}
    },
  },
  // アンケート詳細
  {
    path: '/survey/:id(\\d+)',
    name: 'B-15-2',
    props: true,
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire'},
    component: () => import('../views/survey/leaf.vue')
  },
  // アンケートの新規作成
  {
    path: '/survey/new',
    name: 'B-16-1',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/input.vue')
  },
  // アンケートの編集
  {
    path: '/survey/:id(\\d+)/:pathMatch(edit)',
    name: 'B-16-1edit',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/input.vue')
  },
  // アンケートのコピー
  {
    path: '/survey/:id(\\d+)/:pathMatch(clone)',
    name: 'B-16-1clone',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/input.vue')
  },
  // 設問一覧
  {
    path: '/survey/:id(\\d+)/questions/',
    name: 'B-16-2',
    props: true,
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/questions/index.vue')
  },
  // 設問の追加
  {
    path: '/survey/:id(\\d+)/questions/new',
    name: 'B-16-3',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/questions/input.vue')
  },
  // 設問の編集
  {
    path: '/survey/:id(\\d+)/questions/:qid(\\d+)/:pathMatch(edit)',
    name: 'B-16-2edit',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/questions/input.vue')
  },
  // 回答者一覧
  {
    path: '/survey/:id(\\d+)/respondents',
    name: 'B-16-4',
    props: true,
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/respondents.vue')
  },
  // レポートの新規作成
  {
    path: '/survey/:id(\\d+)/report/new',
    name: 'B-16-6',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/report/input.vue')
  },
  // レポートの新規作成
  {
    path: '/survey/:id(\\d+)/report/:pathMatch(edit)',
    name: 'B-16-6edit',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/survey/report/input.vue')
  },
  // レポート
  {
    path: '/survey/:id(\\d+)/report/',
    name: 'B-16-7',
    props: true,
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire'},
    component: () => import('../views/survey/report/index.vue')
  },
  // レポート 旧URL
  {
    path: '/survey/questionnaires/:id(\\d+)/report',
    redirect: to => {
      return {name: 'B-16-7', params: to.params}
    },
  },
  // アンケート用紙
  {
    path: '/survey/:id(\\d+)/print',
    name: 'B-16-8',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', roles: [1, 2, 3, 4, 5, 6, 7, 12, 13], layout: 'print' },
    component: () => import('../views/survey/print.vue')
  },
  // レポート印刷
  {
    path: '/survey/:id(\\d+)/report/:pathMatch(print)',
    name: 'B-16-9',
    meta: { title: 'アンケート', group: 'enquetepage', use: 'use_questionnaire', layout: 'print' },
    component: () => import('../views/survey/report/index.vue')
  },
  {
    // 出欠確認
    path: '/attends/',
    name: 'B-17-1',
    props: true,
    meta: { title: '出欠確認', group: 'attendpage', use: 'use_attendance' },
    component: () => import('../views/attends/index.vue')
  },
  {
    // 出欠確認の新規作成
    path: '/attends/new',
    name: 'B-18-2',
    props: true,
    meta: { title: '出欠確認', group: 'attendpage', use: 'use_attendance', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/attends/input.vue')
  },
  {
    // 出欠確認 の編集
    path: '/attends/:id(\\d+)/:pathMatch(edit)',
    name: 'B-18-2edit',
    meta: { title: '出欠確認', group: 'attendpage', use: 'use_attendance', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/attends/input.vue')
  },
  {
    // 出欠確認 のコピー
    path: '/attends/:pathMatch(clone)/:id(\\d+)',
    name: 'B-18-2clone',
    props: true,
    meta: { title: '出欠確認', group: 'attendpage', use: 'use_attendance', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/attends/input.vue')
  },
  {
    // 出欠確認詳細
    path: '/attends/:id(\\d+)',
    name: 'B-18-3',
    props: true,
    meta: { title: '出欠確認', group: 'attendpage', use: 'use_attendance' },
    component: () => import('../views/attends/leaf.vue')
  },
  // メンバー一覧
  {
    path: '/members/',
    name: 'B-3-1',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/members/index.vue')
  },
  // メンバー仮登録入力画面
  {
    path: '/members/new',
    name: 'B-3-3',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13] },
    component: () => import('../views/members/input.vue')
  },
  // メンバー編集、再発行確認画面
  {
    path: '/members/:id/confirm',
    name: 'B-3-5forEdit',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13], formData: {} },
    component: () => import('../views/members/confirm_update.vue')
  },
  // メンバー仮登録確認画面
  {
    path: '/members/confirm',
    name: 'B-3-5',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13], formData: {} },
    component: () => import('../views/members/confirm.vue')
  },
  // メンバー仮登録完了画面
  {
    path: '/members/complete/:id',
    name: 'B-3-6',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13], formData: {} },
    component: () => import('../views/members/complete.vue')
  },
  // メンバー詳細
  {
    path: '/members/:id',
    name: 'B-3-2',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/members/leaf.vue')
  },
  // メンバー編集
  {
    path: '/members/:id/:pathMatch(edit)',
    name: 'B-3-2edit',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 6, 10, 11, 12, 13] },
    component: () => import('../views/members/input.vue')
  },
  // メンバー仮IDパス再発行
  {
    path: '/members/:id/:pathMatch(reissue)',
    name: 'B-3-12reissue',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13] },
    component: () => import('../views/members/input.vue')
  },
  // メンバー一括登録
  {
    path: '/members/batch/new',
    name: 'B-3-8',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13] },
    component: () => import('../views/members/batch/input.vue')
  },
  // メンバー一括登録（確認）
  {
    path: '/members/batch/confirm',
    name: 'B-3-9',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13] },
    component: () => import('../views/members/batch/confirm.vue')
  },
  // メンバー一括登録（完了）
  {
    path: '/members/batch/complete',
    name: 'B-3-10',
    props: true,
    meta: { title: 'メンバー一覧', group: 'memberpage', roles: [1, 2, 3, 5, 10, 11, 12, 13] },
    component: () => import('../views/members/batch/complete.vue')
  },
  // グループの設定
  {
    path: '/groups/',
    name: 'B-3-1-1',
    props: true,
    meta: { title: 'グループの設定', group: 'grouppage', roles: [1, 2, 3, 10, 11, 12, 13] },
    component: () => import('../views/groups/index.vue')
  },
  // グループの新規作成
  {
    path: '/groups/new',
    name: 'B-3-1-3',
    meta: { title: 'グループの設定', group: 'grouppage', roles: [1, 2, 3, 10, 11, 12, 13] },
    component: () => import('../views/groups/input.vue')
  },
  // グループの編集
  {
    path: '/groups/:id/:pathMatch(edit)',
    name: 'B-3-1-4',
    meta: { title: 'グループの設定', group: 'grouppage', roles: [1, 2, 3, 10, 11, 12, 13] },
    component: () => import('../views/groups/input.vue')
  },
  // グループの詳細
  {
    path: '/groups/:id/',
    name: 'B-3-1-5',
    props: true,
    meta: { title: 'グループの設定', group: 'grouppage', roles: [1, 2, 3, 10, 11, 12, 13] },
    component: () => import('../views/groups/leaf.vue')
  },
  // 掲示板
  {
    path: '/entries/',
    name: 'B-10-1',
    props: true,
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/index.vue')
  },
  // 掲示板検索
  {
    path: '/entries/search',
    name: 'B-10-2',
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/search.vue')
  },
  // トピック詳細
  {
    path: '/entries/:id(\\d+)',
    name: 'B-11-1',
    props: true,
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/leaf.vue')
  },
  // トピック の新規作成
  {
    path: '/entries/new',
    name: 'B-11-3',
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/input.vue')
  },
  // トピック の新規作成
  {
    path: '/entries/:id(\\d+)/:pathMatch(edit)',
    name: 'B-11-3edit',
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/input.vue')
  },
  // 承認待ちのトピック
  {
    path: '/entries/attention',
    name: 'B-11-4',
    props: true,
    meta: { title: '掲示板', group: 'boardpage', use: 'use_bbs' },
    component: () => import('../views/entries/attention.vue')
  },
  // 意見を投稿する
  {
    path: '/opinion/new',
    name: 'B-19-1',
    meta: { title: 'ご意見箱', group: 'opinionpage', use: 'use_opinion_box' },
    component: () => import('../views/opinion/input.vue')
  },
  // ご意見箱投稿完了
  {
    path: '/opinion/complete',
    name: 'B-19-2',
    meta: { title: 'ご意見箱', group: 'opinionpage', use: 'use_opinion_box' },
    component: () => import('../views/opinion/complete.vue')
  },
  // ご意見箱
  {
    path: '/opinion/',
    name: 'B-20-1',
    props: true,
    meta: { title: 'ご意見箱', group: 'opinionpage', use: 'use_opinion_box', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/opinion/index.vue')
  },
  // ご意見箱詳細
  {
    path: '/opinion/:id(\\d+)',
    name: 'B-20-2',
    meta: { title: 'ご意見箱', group: 'opinionpage', use: 'use_opinion_box', roles: [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13] },
    component: () => import('../views/opinion/leaf.vue')
  },
  // ご意見箱詳細 旧URL
  {
    path: '/opinion/opinion_boxes/:id(\\d+)',
    redirect: to => {
      return {name: 'B-20-2', params: to.params}
    },
  },
  // 防災
  {
    path: '/disaster',
    name: 'B-21-1',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/index.vue')
  },
  // 安否情報の報告
  {
    path: '/disaster/rooms/:id(\\d+)/new',
    name: 'B-21-2',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/rooms/input.vue')
  },
  // 自宅の安否情報の報告
  {
    path: '/disaster/rooms/new',
    name: 'B-21-2self',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/rooms/input.vue')
  },
  // 安否情報の編集
  {
    path: '/disaster/rooms/:id(\\d+)/room_comments/:cid(\\d+)/:pathMatch(edit)',
    name: 'B-21-2edit',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/rooms/input.vue')
  },
  // 集計の詳細
  {
    path: '/disaster/rooms/',
    name: 'B-21-4',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/rooms/index.vue')
  },
  // 安否情報詳細
  {
    path: '/disaster/rooms/:id(\\d+)',
    name: 'B-21-5',
    props: true,
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available' },
    component: () => import('../views/disaster/rooms/leaf.vue')
  },
  // 防災の設定
  {
    path: '/disaster/setting',
    name: 'B-21-6',
    props: true,
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/setting.vue')
  },
  // 新しいブロック（部屋）を登録
  {
    path: '/disaster/blocks/new',
    name: 'B-21-7',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/blocks/input.vue')
  },
  // ブロック名と部屋を編集する
  {
    path: '/disaster/blocks/:id(\\d+)/:pathMatch(edit)',
    name: 'B-21-7edit',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/blocks/input.vue')
  },
  // 登録したブロックの一覧
  {
    path: '/disaster/blocks/',
    name: 'B-21-8',
    props: true,
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/blocks/index.vue')
  },
  // 安否情報を保存する
  {
    path: '/disaster/archives/new',
    name: 'B-21-9',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/archives/input.vue')
  },
  // 保存した安否情報
  {
    path: '/disaster/archives/',
    name: 'B-21-10',
    props: true,
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/archives/index.vue')
  },
  // 防災トップ表示設定
  {
    path: '/disaster/notice_category/',
    name: 'B-21-12',
    meta: { title: '防災', group: 'emergencypage', use: 'disaster_available', roles: [1, 2, 3, 4, 10, 11, 12, 13] },
    component: () => import('../views/disaster/notice_category/index.vue')
  },
  {
    // プレミアムプランのご紹介
    path: '/premium/',
    name: 'B-22-1',
    props: true,
    meta: { title: 'プレミアムプランのご紹介' },
    component: () => import('../views/premium/index.vue')
  },
  {
    // 各種申請
    path: '/apply/application_forms/',
    name: 'apply-application-forms',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/application_forms/index.vue')
  },
  {
    // 各種申請 自分の申請履歴
    path: '/apply/applications/mine',
    name: 'apply-applications-mine',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/applications/mine/index.vue')
  },
  {
    // 各種申請 申請内容
    path: '/apply/applications/:id(\\d+)',
    name: 'apply-applications-id',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/applications/leaf.vue')
  },
  {
    // 各種申請 申請一覧
    path: '/apply/applications/approvable',
    name: 'apply-applications-approvable',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/applications/approvable/index.vue')
  },
  {
    // 各種申請 申請の作成画面
    path: '/apply/applications/new',
    name: 'apply-applications-new',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/applications/input.vue')
  },
  {
    // 各種申請 申請の編集画面
    path: '/apply/applications/:id(\\d+)/edit',
    name: 'apply-applications-edit',
    props: true,
    meta: { title: '各種申請' },
    component: () => import('../views/apply/applications/input.vue')
  },
  // ログインページ
  {
    path: '/user_session/new',
    name: 'A-0',
    props: true,
    meta: { title: 'TOP', login: 'guest' },
    component: () => import('../views/login.vue')
  },
  {
    path: '/*',
    name: 'NotFound',
    meta: { title: 'お探しのページは見つかりませんでした。', login: 'all' },
    component: () => import('../views/notfound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
})

const reload_display = () => {
  window.location.reload();
};

const reload_display_transition_by_cache = (event) => {
  if(event.persisted) {
    window.location.reload();
  }
};

// SafariでHistoryAPIを使用するとalert, confirm, promptメソッドが動作しない為、リロードしている
router.afterEach(() => {
  const agent = window.navigator.userAgent;
  if (/^.*(like Mac OS X.*\))(?!.*(CriOS|FxiOS|EdgiOS)).*(Safari).*/.test(agent)) {
    window.removeEventListener('pageshow', reload_display_transition_by_cache)

    window.addEventListener('popstate', reload_display)
    window.addEventListener('pageshow', reload_display_transition_by_cache)
  }
})

export default router
