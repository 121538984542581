<template>
  <nav class="leftnavi" v-bind:class="{open: globalMenu}" v-bind:data-view="view">
    <a class="lnavi_close" v-on:click="$parent.closeGlobalMenu">
      <icon name="ico-close" viewBox="0 0 18 18"></icon>
    </a>
    <ul v-if="user_session">
      <li class="navi_home">
        <router-link to="/">
          <icon name="ico-cat-home" viewBox="0 0 34.88 33.85"></icon>
          ホーム
        </router-link>
      </li>
      <li class="navi_reserv" v-if="apartments.attributes.use_reservation">
        <router-link v-bind:to="[5,6,7].includes(user_session.attributes.role_id) ? '/facility/' : '/facility/services'">
          <icon name="ico-cat-facility" viewBox="0 0 34.67 34.67"></icon>
          施設予約
        </router-link>
      </li>
      <li class="navi_news" v-if="apartments.attributes.use_notice">
        <router-link to="/notices/">
          <icon name="ico-cat-notices" viewBox="0 0 34.6 34.6"></icon>
          お知らせ
        </router-link>
      </li>
      <li class="navi_calendar" v-if="apartments.attributes.use_calendar">
        <router-link to="/events/">
          <icon name="ico-cat-calendars" viewBox="0 0 34.88 31.22"></icon>
          カレンダー
        </router-link>
      </li>
      <li class="navi_board" v-if="apartments.attributes.use_bbs">
        <router-link to="/entries/">
          <icon name="ico-cat-entries" viewBox="0 0 34.6 34.6"></icon>
          掲示板
        </router-link>
      </li>
      <li class="navi_enquete" v-if="apartments.attributes.use_questionnaire">
        <router-link to="/survey/">
          <icon name="ico-cat-survey" viewBox="0 0 34.83 35.08"></icon>
          アンケート
        </router-link>
      </li>
      <li class="navi_emergency" v-if="apartments.attributes.disaster_available">
        <router-link to="/disaster/">
          <icon name="ico-cat-disaster" viewBox="0 0 30.32 33.75"></icon>
          防災
        </router-link>
      </li>
      <li class="navi_opinion" v-if="apartments.attributes.use_opinion_box">
        <router-link to="/opinion/new">
          <icon name="ico-cat-opinion" viewBox="0 0 34.88 33.08"></icon>
          ご意見箱
        </router-link>
      </li>
      <li class="navi_data">
        <router-link to="/resources/">
          <icon name="ico-cat-resources" viewBox="0 0 35.59 35.68"></icon>
          データ保存箱
        </router-link>
      </li>
      <li class="navi_meeting" v-if="apartments.attributes.use_board_discussion">
        <router-link to="/board/">
          <icon name="ico-cat-board" viewBox="0 0 36.53 36.58"></icon>
          会議管理
        </router-link>
      </li>
      <li class="navi_attend" v-if="apartments.attributes.use_attendance">
        <router-link to="/attends/">
          <icon name="ico-cat-attends" viewBox="0 0 36.6 36.69"></icon>
          出欠確認
        </router-link>
      </li>
      <li v-if="apartments.attributes.use_apply">
        <router-link to="/apply/application_forms/">
          <icon name="ico-cat-apply" viewBox="0 0 50 50"></icon>
          各種申請
        </router-link>
      </li>
      <li class="navi_config" v-if="isAdminPartyUpperCaretakers(user_session.attributes.role_id)">
        <span class="nolink">
          <icon name="ico-cat-setting" viewBox="0 0 35.97 37.15"></icon>
          設定
        </span>
        <ul>
          <li v-if="isAdminPartyUpperCaretakers(user_session.attributes.role_id)"><router-link to="/apartments/">マンションの設定</router-link></li>
          <li v-if="isAdminPartyUpperCaretakers(user_session.attributes.role_id)"><router-link to="/apartments/contents/">コンテンツの設定</router-link></li>
          <li v-if="isAdminPartyUpperCaretakers(user_session.attributes.role_id)"><router-link to="/apartments/associate_links/">バナー・関連リンクの設定</router-link></li>
          <li v-if="isAdminPartyUpperCaretakers(user_session.attributes.role_id)">
            <router-link to="/members/">メンバーの設定</router-link>
            <ul v-if="isMemberMaintainable(user_session.attributes.role_id)">
              <li><router-link to="/members/new">新メンバーを登録</router-link></li>
              <li v-if="isMemberMaintainable(user_session.attributes.role_id)"><router-link to="/members/batch/new">メンバーの一括登録</router-link></li>
            </ul>
          </li>
          <li v-if="isAdminPartyUpperManagers(user_session.attributes.role_id)">
            <router-link to="/groups/">グループの設定</router-link>
            <ul>
              <li><router-link to="/groups/new">新規グループの作成</router-link></li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="premium" v-if="apartments.attributes.plan == 1">
        <router-link to="/premium/">
          <icon name="ico-premium" viewBox="0 0 202.78 185.56"></icon>
          プレミアムプラン
        </router-link>
      </li>
      <li class="navi_footer">
        <p><a href="/inquiries/new" target="_blank">お問い合わせ</a></p>
        <p><a href="https://www.tsunagunet.com/profile/outline.html" target="_blank">会社概要</a></p>
        <p><a href="https://www.tsunagunet.com/privacy/" target="_blank">個人情報保護の取り組み</a></p>
        <p><a href="/terms" target="_blank">利用規約</a></p>
        <p><a href="https://help.m-cloud.jp/" target="_blank">ユーザーサポート</a></p>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: ['user_session', 'apartments', 'globalMenu', 'view'],
}
</script>
