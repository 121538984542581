import { ref, unref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const _target = ref()
const _message = ref()
const _message_links = ref()

export function useMessage() {
  const route = useRoute()
  const router = useRouter()

  router.beforeResolve((to) => {
    if (_target.value == null) _target.value = to.name

    if (_target.value != to.name) {
      _message.value = null
      _message_links.value = null
    }
  })

  const setMessage = (message, links = null) => {
    _target.value = route.name
    _message.value = unref(message)
    _message_links.value = unref(links)
  }

  const pushMessage = (message, links = null) => {
    _target.value = null
    _message.value = unref(message)
    _message_links.value = unref(links)
  }

  const message = computed(() => {
    return route.name == _target.value ? _message.value : null
  })

  const message_links = computed(() => {
    return route.name == _target.value ? _message_links.value : null
  })

  return {
    setMessage,
    pushMessage,
    message,
    message_links
  }
}
