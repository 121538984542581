<template>
  <div class="green" v-bind:class="this.$route.meta.group">
    <div class="loader">Loading...</div>
    <PageHeader v-bind:user_session="user_session" v-bind:apartments="apartments" v-on:logout="$emit('logout')" />

    <main class="contentsarea">
      <a class="lnavi_open" v-on:click="openGlobalMenu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 25.99">
          <rect width="30" height="2"/>
          <rect y="10" width="30" height="2"/>
          <path d="M12.85,35.88l.41-6.4h1l2.06,5.22,2.13-5.22h.88l.42,6.4H19l-.34-5.64-2.18,5.37H16l-2.14-5.37-.35,5.64Z" transform="translate(-11 -10)"/>
          <path d="M21.52,35.88v-6.4h3.67v.63h-3v2.12h2.61v.6H22.2v2.42h3.06v.63Z" transform="translate(-11 -10)"/>
          <path d="M26.84,35.88v-6.4h1L31.15,35V29.48h.66v6.4h-.92l-3.41-5.71v5.71Z" transform="translate(-11 -10)"/>
          <path d="M34.49,33.61a1.74,1.74,0,1,0,3.48,0V29.48h.67v4.07A2.22,2.22,0,0,1,36.21,36a2.16,2.16,0,0,1-2.41-2.44V29.48h.69Z" transform="translate(-11 -10)"/>
        </svg>
      </a>

      <LeftNavi v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:globalMenu="pcMenu" v-bind:view="'pc'"/>
      <LeftNavi v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:globalMenu="spMenu" v-bind:view="'sp'"/>

      <article class="contents">
        <section class="newsform">
          <div class="form_inner">
            <h1>このページにはアクセスできません。<br>下記のいずれかの理由により<br class="sp">このページにアクセスできません。</h1>
            <div class="formbk errorblock">
              <p>・公開範囲の対象グループに所属していない。</p>
              <p>・特定の操作権限をお持ちでない。</p>
              <p>・すでに削除されている。</p>
              <p>・URLが間違っている。</p>
            </div>
          </div>
        </section>

      </article>
    </main>

    <PageFooter/>
  </div>

</template>
<script>
import PageHeader from '@/components/header.vue';
import PageFooter from '@/components/footer.vue';
import LeftNavi from '@/components/leftNavi.vue';
import Icon from '@/components/icon.vue';

export default {
  components: {
    PageHeader,
    PageFooter,
    LeftNavi,
  },
  props: ['user_session', 'apartments', 'buildings', 'holiday'],
  data: function () {
    return {
      pcMenu: true,
      spMenu: false,
    }
  },
  methods: {
    // グロナビを開く
    openGlobalMenu: function () {
      this.pcMenu = true;
      this.spMenu = true;
    },
    // グロナビを閉じる
    closeGlobalMenu: function () {
      this.pcMenu = false;
      this.spMenu = false;
    },
  },
  created: function () {
    document.body.addEventListener('click', function (e) {
      // スマフォ時グロナビを閉じる
      if (e.target.closest('.lnavi_open') == null) {
        this.spMenu = false;
      }
      // サブメニューを閉じる
      if (e.target.closest('.submenubtn') == null) {
        document.querySelectorAll('.submenubtn').forEach(function (elem) {
          elem.classList.remove('open');
        });
      }
      // ツールチップを閉じる
      if (e.target.closest('.position') == null) {
        document.querySelectorAll('.position').forEach(function (elem) {
          elem.querySelector('.help_link').classList.remove('open');
        });
      }
    }.bind(this));
  },
}
</script>

<style scoped>
.newsform .formbk.errorblock {
  max-width: 580px;
  margin: auto;
}
</style>
