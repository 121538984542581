<template>
  <template v-if="guides.length">
    <ModalImage ref="helpModalRef" :pc_images="pc_images" :sp_images="sp_images" :transision_mode="transision_mode"/>
    <a @click="openHelp" class="help_link">？</a>
  </template>
</template>

<script>
import ModalImage from '@/components/modal/overlay-image.vue';
import { useOverlayStatus } from '@/composables/overlay-status';

export default {
  props: {
    category_id: Number,
  },
  components: {
    ModalImage
  },
  setup() {
    const { guideLength, setGuideStatus, clearCompName, viewable } = useOverlayStatus();
    // this.アクセス用
    return { guideLength, setGuideStatus, clearCompName, viewable };
  },
  data: function() {
    return {
      guides: [],
      pc_images: [],
      sp_images: [],
      transision_mode: false,
    }
  },
  methods: {
    getGuide() {
      if (!this.category_id) {
        this.pc_images = []
        this.sp_images = []
        return
      }
      const params = {
        'q[function_category_id_eq]': this.category_id
      }

      this.$store
        .dispatch('jv/get', ['guides', { params: params }])
        .then((response) => {
          this.$store.commit('setGuide', response)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(async () => {
          const { _jv, ...data } = this.$store.state.guide
          // v-if="guides.lengthがfalseの時this.$refs.helpModalRefが取得できないため処理を待つ"
          await(this.guides = _jv['json']['data'].map(a => data[a.id]))
          this.guides.forEach(
            function name(data) {
              if (data.image_type == 0) {
                this.pc_images = this.pc_images.concat(data.image_urls);
              } else if (data.image_type == 1) {
                this.sp_images = this.sp_images.concat(data.image_urls);
              }
            }.bind(this)
          )
          if(this.$route.name == 'Home'){
            this.setGuideStatus(this.guides.filter(data => !data.read).length)
          } else if (this.guides.filter(data => !data.read).length) {
            this.openHelp()
          }
        })
    },
    // ヘルプモーダル
    openHelp: function () {
      this.$refs.helpModalRef.openModal()
    }
  },
  mounted: function () {
    this.getGuide();
  },
  watch: {
    'viewable.value': function (){
      let guidesSize = this.guides.filter(data => !data.read).length
      if (guidesSize) {
        this.setGuideStatus(guidesSize)
        if (this.viewable.value == 'guide') {
          this.openHelp()
        } else {
          this.clearCompName()
        }
      }
    },
    'guideLength.value': function () {
      useOverlayStatus()
    }
  }
}
</script>
