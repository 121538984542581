<template>
  <div id="main" class="green" v-bind:class="this.$route.meta.group" v-if="apartments">
    <div class="loader">Loading...</div>
    <main class="contentsarea">
      <router-view v-bind:user_session="user_session" v-bind:apartments="apartments" v-bind:buildings="buildings" v-bind:holiday="holiday" ref="body"/>
    </main>

    <Footer/>
  </div>

</template>
<style>
.ertxt {
  display: none;
}
.erbox [data-invalid]:invalid ~ .ertxt {
  display: block;
}
.upload label[data-dragover='true'] {
  border-style: solid;
  border-color: #000;
}
</style>
<script>
import Icon from '@/components/icon.vue';

export default {
  props: ['user_session', 'apartments', 'buildings', 'holiday'],
  data: function () {
    return {
      pcMenu: false,
      spMenu: false,
      modal_component: null,
      modal_data: null,
      modal_callback: null,
      modal_params: null,
    }
  },
  methods: {
    // グロナビを開く
    openGlobalMenu: function () {
      this.pcMenu = true;
      this.spMenu = true;
    },
    // グロナビを閉じる
    closeGlobalMenu: function () {
      this.pcMenu = false;
      this.spMenu = false;
    },
  },
  created: function () {
    document.body.addEventListener('click', function (e) {
      // スマフォ時グロナビを閉じる
      if (e.target.closest('.lnavi_open') == null) {
        this.spMenu = false;
      }
      // サブメニューを閉じる
      if (e.target.closest('.submenubtn') == null) {
        document.querySelectorAll('.submenubtn').forEach(function (elem) {
          elem.classList.remove('open');
        });
      }
      // ツールチップを閉じる
      if (e.target.closest('.position') == null) {
        document.querySelectorAll('.position').forEach(function (elem) {
          elem.querySelector('.help_link').classList.remove('open');
        });
      }
    }.bind(this));
  },
}
</script>
