import { ref, computed } from 'vue'

var guideLength = ref(null);
var fullscreenBannerLength = ref(null);
var visible = ref(null);

export function useOverlayStatus() {
  const setGuideStatus = (num) => (guideLength.value = num);
  const setFullscreenBannerStatus = (num) => (fullscreenBannerLength.value = num);

  // 変更点ガイド, 全画面バナー表示切替ロジック
  const viewable = computed(() => {
    if (guideLength.value != null && fullscreenBannerLength.value != null){
      if(guideLength.value > 0){
        visible.value = 'guide'
      }else if (guideLength.value == 0 && fullscreenBannerLength.value >= 0) {
        visible.value = 'fullscreen-banner'
      }else{
        visible.value = null
      }
    } else {
      visible.value = null
    }
    return visible
  })

  const clearCompName = () => {
    guideLength.value = null;
    fullscreenBannerLength.value = null;
  }
  return {
    guideLength,
    fullscreenBannerLength,
    setGuideStatus,
    setFullscreenBannerStatus,
    clearCompName,
    viewable,
  };
}
